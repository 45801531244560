import * as React from "react";
import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import { BlogPosts } from "../components/BlogPosts";
import Navigation from "../components/Navigation";

const Homepage = ({ data }) => {
  if (!data) return null;
  const docs = data.allPrismicPost;

  // const avatar = { backgroundImage: `url(${home.image.url})` }

  return (
    <Layout>
      <Navigation />
      <BlogPosts docs={docs} />
    </Layout>
  );
};

export default Homepage;

export const query = graphql`
  query MyQuery($limit: Int!, $skip: Int!) {
    prismicHomepage {
      _previewable
      data {
        headline {
          text
        }
        image {
          url
        }
      }
    }
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        _previewable
        id
        url
        data {
          tags
          title {
            text
          }
          categories {
            category {
              document {
                ... on PrismicCategories {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          date(formatString: "MMM D, YYYY")
          body {
            ... on PrismicPostDataBodyText {
              id
              slice_label
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`;
