import * as React from "react";
import { Link } from "gatsby";

const getPageNumberPath = (currentIndex) => {
  if (currentIndex === 0) {
    return "/1";
  }
  return "/" + (currentIndex + 1);
};

export const Pagination = ({ pageInfo }) => {
  if (!pageInfo) return null;
  const { currentPage, pageCount } = pageInfo;

  const prevPagePath =
    currentPage - 1 === 1 ? "/1" : "/" + (currentPage - 1).toString();
  const nextPagePath = "/" + (currentPage + 1).toString();

  const prevClass = currentPage === 1 ? "disabled" : "enabled";
  const nextClass = currentPage === pageCount ? "disabled" : "enabled";

  return (
    <div className="pagination">
      {Array.from({ length: pageCount }, (_, i) => {
        let numClass = "pageNumber";
        console.log(currentPage);
        if (currentPage === 1) {
          return null;
        }
        if (currentPage === i + 1) {
          numClass = "currentPage";
        }
        return (
          <>
            <Link className={prevClass} to={prevPagePath} rel="prev">
              {"<"}
            </Link>
            <Link to={getPageNumberPath(i)} className={numClass} key={i + 1}>
              {i + 1}
            </Link>
            <Link className={nextClass} to={nextPagePath} rel="next">
              {">"}
            </Link>
          </>
        );
      })}
    </div>
  );
};
