import * as React from "react";
import { Link } from "gatsby";

import { Pagination } from "../utils/pagination";

// A summary of the Blog Post
const PostSummary = ({ post, id }) => {
  const title = post.data.title.text;
  const titleText = title.charAt(0).toUpperCase() + title.slice(1);
  return (
    <div className="lg:mt-10 flex-1 lg:p-10 max-w-4xl">
      <div className=" text-gray-100 py-4 lg:px-8" key={id}>
        <h2 className="text-blue-950 uppercase  text-4xl font-bold py-1 mt-6 pl-4 pr-8 inline-flex">
          <Link to={post.url}>{titleText}</Link>
        </h2>
      </div>
    </div>
  );
};

export const BlogPosts = ({ docs }) => {
  if (!docs) return null;

  const posts = docs.nodes;

  return (
    <>
      <div className="mt-16 md:mt-40 mb-20 flex flex-col lg:flex-row flex-wrap items-stretch lg:space-x-6">
        {posts.map((post) => (
          <PostSummary post={post} key={post.id} />
        ))}
      </div>
      <Pagination pageInfo={docs.pageInfo} />
    </>
  );
};
